<template>
    <CompContent class="monitoring-index" title="数据库使用情况统计">
        <template #operate>
            <DatePicker v-model="dates" type="daterange" style="width: 250px" placeholder="时间段"></DatePicker>
        </template>

        <ElemChart :option="chartOpt" style="height: 350px;margin: 20px 10px 10px;width: initial;overflow: hidden;flex-shrink: 0;">
            <template #head>
                <div class="title" style="margin: 0">访问实时数据</div>
                <div class="data-time">·数据时间 {{ $core.formatDate(new Date(), "yyyy-MM-dd hh:00") }}</div>
                <div class="sift-box">
                    <div class="name">指标筛选</div>
                    <div class="value-box">
                        <div class="item" :class="{ ac: ac_view === 'UV' }" @click="ac_view = 'UV'">访问人数</div>
                        <div class="item" :class="{ ac: ac_view === 'PV' }" @click="ac_view = 'PV'">访问次数</div>
                        <div class="item" :class="{ ac: ac_view === 'OV' }" @click="ac_view = 'OV'">访问机构数</div>
                    </div>
                </div>
            </template>
        </ElemChart>
        <div class="modal-box">
            <div class="title">数据库整体访问情况</div>
            <div class="item-box">
                <div class="name">数据库访问次数</div>
                <div class="hint-box">
                    <div class="name">昨日</div>
                    <div class="value">{{ viewStat ? viewStat.yesterdayStat.pv : 0 }}</div>
                </div>
                <div class="hint-box">
                    <div class="name">本月</div>
                    <div class="value">{{ viewStat ? viewStat.thisMonthStat.pv : 0 }}</div>
                </div>
                <div class="hint-box">
                    <div class="name">全部</div>
                    <div class="value">{{ viewStat ? viewStat.allStat.pv : 0 }}</div>
                </div>
            </div>
            <div class="item-box">
                <div class="name">数据库访问人数</div>
                <div class="hint-box">
                    <div class="name">昨日</div>
                    <div class="value">{{ viewStat ? viewStat.yesterdayStat.uv : 0 }}</div>
                </div>
                <div class="hint-box">
                    <div class="name">本月</div>
                    <div class="value">{{ viewStat ? viewStat.thisMonthStat.uv : 0 }}</div>
                </div>
                <div class="hint-box">
                    <div class="name">全部</div>
                    <div class="value">{{ viewStat ? viewStat.allStat.uv : 0 }}</div>
                </div>
            </div>
            <div class="item-box">
                <div class="name">数据库访问机构数</div>
                <div class="hint-box">
                    <div class="name">昨日</div>
                    <div class="value">{{ viewStat ? viewStat.yesterdayStat.ov : 0 }}</div>
                </div>
                <div class="hint-box">
                    <div class="name">本月</div>
                    <div class="value">{{ viewStat ? viewStat.thisMonthStat.ov : 0 }}</div>
                </div>
                <div class="hint-box">
                    <div class="name">全部</div>
                    <div class="value">{{ viewStat ? viewStat.allStat.ov : 0 }}</div>
                </div>
            </div>
        </div>
        <div class="chart-modal-box">
            <!-- <div class="item-box">
                <ElemChart title="数据库各区访问机构数排行" :option="orgRankingsOption" style="padding: 15px 0 0 15px;overflow: hidden;"></ElemChart>
            </div>
            <div class="item-box">
                <ElemChart title="访问实时数据" :option="chartOpt" style="padding: 15px 0 0;overflow: hidden;"></ElemChart>
            </div> -->
            <div class="item-box" style="width: calc(100% - 40px);height: 700px" @click="Route.jump('/monitoringdatabatedetail')">
                <ElemChart :option="functionRequestStatOption" style="overflow: hidden;"></ElemChart>
            </div>
        </div>
    </CompContent>
</template>

<script>
import ElemTable from "../../communitymobilization/components/ElemTable.vue"
import Route from "../../communitymobilization/entity/Route"
import CompContent from "../../customform/components/CompContent.vue"
import Request from "../../jointly/utils/request"
import ElemChart from "../../managsystem/childrens/ElemChart.vue"

// class Req {
//     static get(url) {
//         return new Promise((resolve, reject) => {
//             const xhr = new XMLHttpRequest()

//             xhr.open("GET", "https://api.gateway.prod.liefengtech.com" + url, true)

//             xhr.setRequestHeader("Authorization", "b413d6a5-3fc4-4450-89ab-636ad3d99c4f")

//             xhr.onreadystatechange = function() {
//                 if (xhr.readyState === 4) {
//                     if (xhr.status === 200) {
//                         const data = JSON.parse(xhr.responseText)
//                         resolve(data.data || data.dataList)
//                     } else {
//                         reject(xhr.responseText)
//                     }
//                 }
//             }

//             xhr.send()
//         })
//     }
// }

export default {
    components: {
        CompContent,
        ElemTable,
        ElemChart,
    },

    data() {
        return {
            Route: Route,
            // 日期段
            dates: [
                (() => {
                    const date = new Date()
                    date.setDate(date.getDate() - 7)
                    return date
                })(),
                new Date(),
            ],

            columns: [
                {
                    title: "分组",
                    key: "houseNum",
                    minWidth: 120,
                },
                {
                    title: "异常描述",
                    key: "houseNum",
                    minWidth: 120,
                },
                {
                    title: "最近发生时间",
                    key: "houseNum",
                    minWidth: 120,
                },
                {
                    title: "等级",
                    key: "houseNum",
                    minWidth: 120,
                },
                {
                    title: "状态",
                    key: "houseNum",
                    minWidth: 120,
                },
            ],

            ac_view: "UV",
            // 整体访问情况
            viewStat: null,
            chartOpt: null,
            functionRequestStatOption: null,
        }
    },

    watch: {
        ac_view: {
            handler(v) {
                this.getCurrentTimeStat(v)
            },
            immediate: true,
        },

        dates(v) {
            this.getFunctionRequestStat()
        },
    },

    mounted() {
        var v = [
            {
                dimensionName: 1,
                overdueOrgNum: 100,
            },
            {
                dimensionName: 2,
                overdueOrgNum: 80,
            },
        ]

        this.getViewStat()
        this.getFunctionRequestStat()
    },

    methods: {
        getViewStat() {
            Request.get("/gateway/api/ck/SystemOperateLog/getViewStat").then(res => {
                this.viewStat = res
            })
        },

        getCurrentTimeStat(type) {
            Request.get("/gateway/api/ck/SystemOperateLog/getCurrentTimeStat?viewStatType=" + type).then(res => {
                // 24 小时数组
                const hours = Array.from({ length: 24 }, (v, i) => i)

                this.chartOpt = {
                    xAxis: {
                        type: "category",
                        data: hours,
                        axisLabel: {
                            interval: 0,
                            fontSize: 10,
                            formatter: function(value) {
                                var strs = []

                                // 每隔4个字符换行
                                for (var i = 0, s; (s = value.substr(i, 4)); i += 4) {
                                    strs.push(s)
                                }

                                return strs.join("\n")
                            },
                        },
                    },
                    grid: {
                        top: 50,
                        left: 40,
                        right: 40,
                        bottom: 40,
                    },
                    tooltip: {
                        trigger: "axis",
                    },
                    yAxis: {
                        type: "value",
                    },
                    series: [
                        ["todayStat", "今日"],
                        ["yesterdayStat", "1天前"],
                        ["weekStat", "7天前"],
                    ].map(key => {
                        return {
                            name: key[1],
                            data: hours.map(item => {
                                const find = res[key[0]].find(v => v.hour == item)
                                return find ? find.total : key[0] == "todayStat" ? (new Date().getHours() > item ? 0 : null) : 0
                            }),
                            type: "line",
                            smooth: true,
                            label: {
                                show: true,
                                position: "top",
                            },
                        }
                    }),
                }
            })
        },

        getFunctionRequestStat() {
            Request.get(`/gateway/api/ck/SystemOperateLog/getFunctionRequestStat?startDate=${this.$core.formatDate(this.dates[0], "yyyy-MM-dd")}&endDate=${this.$core.formatDate(this.dates[1], "yyyy-MM-dd")}`).then(res => {
                // 计算名称最大长度
                var width = 0

                res.forEach(v => {
                    let w = v.name.length * 15

                    if (w > width) {
                        width = w
                    }
                })

                // 排序
                res.sort((a, b) => a.total - b.total)

                this.functionRequestStatOption = {
                    title: {
                        text: "数据库功能访问次数排行",
                        left: "left",
                        textStyle: {
                            fontSize: 16,
                        },
                    },
                    color: ["#5470c6"],
                    grid: {
                        top: 50,
                        left: width || 40,
                        right: 50,
                        bottom: 40,
                    },
                    xAxis: {
                        type: "value",
                    },
                    yAxis: {
                        type: "category",
                        data: res.map(v => v.name),
                    },
                    series: [
                        {
                            data: res.map(v => v.total),
                            type: "bar",
                            label: {
                                show: true,
                                position: "right",
                                valueAnimation: true,
                            },
                        },
                    ],
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.monitoring-index {
    .data-time {
        font-size: 12px;
        color: #909399;
        margin: 5px 0 10px 0;
    }

    .sift-box {
        display: flex;
        align-items: center;

        .name {
            font-size: 14px;
            color: #303133;
            margin-right: 10px;
        }

        .value-box {
            display: flex;
            align-items: center;

            .item {
                margin-right: 10px;
                font-size: 13px;
                color: #909399;
                cursor: pointer;
                padding: 5px 12px;
                border-radius: 8px;
                background: #f3f3f3;
                transition: all 0.3s;
                line-height: 1;

                &.ac {
                    color: #fff;
                    background: #409eff;
                }
            }
        }
    }

    .title {
        font-size: 16px;
        color: #303133;
        font-weight: 600;
        margin: 10px 0 5px 10px;
        width: 100%;
    }

    .modal-box {
        margin: 10px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item-box {
            margin: 10px;
            width: calc(33.33% - 20px);
            height: 120px;
            border: 1px solid #e3e3e3;
            border-radius: 4px;
            padding: 10px 15px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            border-radius: 6px;

            .name {
                font-size: 14px;
                color: #909399;
            }
            .value {
                font-size: 24px;
                color: #303133;
            }
            .hint-box {
                display: flex;
                justify-content: space-between;
                .name {
                    font-size: 14px;
                    color: #909399;
                }
                .value {
                    font-size: 14px;
                    color: #303133;
                }
            }
        }
    }

    .chart-modal-box {
        display: flex;
        flex-wrap: wrap;

        > .item-box {
            cursor: pointer;
            height: 500px;
            margin: 20px;
            width: calc(50% - 40px);

            @media screen and (max-width: 700px) {
                width: calc(100% - 40px);
            }
        }
    }
}
</style>
